import request from "../../shared/lib/request";

/*
 * Gets the media
 */

export type MediaProps = {
    skip: number;
    top: number;
    include: string[];
    orderby: string[];
}

export type Media = {
    campaignMedia: {}[];
    client_id: number;
    created_at: number;
    created_by: number;
    id: number;
    in_inbox: number;
    mediaLocations: {}[];
    mediaMediaTypes: {
        created_at: number;
        created_by: number;
        mediaType: {
            client_id: number;
            created_at: number;
            created_by: number;
            id: number;
            is_general: number;
            media_type_group_id: number;
            name: string;
            object_type: number;
            status: number;
            status_system: number;
            updated_at: number;
            updated_by: number;
        }[];
        media_id: number;
        type_id: number;
    }[];
    mediaScreengroups: {}[];
    mediaTags: {}[];
    mediaVersions: {
        client_id: number;
        created_at: number;
        created_by: number;
        files: {
            bitrate: number;
            client_id: number;
            created_at: number;
            created_by: number;
            duration: string;
            encoding: string;
            format: string;
            height: number;
            id: number;
            mime_type: string;
            object_type: number;
            rotation: number;
            size: number;
            subtype: number;
            target_id: number;
            target_type: number;
            transpose: number;
            type: number;
            updated_at: number;
            updated_by: number;
            url: string;
            width: number;
        }[];
        hash: string;
        id: number;
        mediaVersionTags: {}[];
        mediaVersionTargetAudiences: {}[];
        media_id: number;
        name: string;
        object_type: number;
        option_set: any | null;
        process_progress_current: number;
        process_progress_total: number;
        rating_id: number;
        status: number;
        status_flow: number;
        status_system: number;
        status_tech_process: number;
        status_tech_upload: number;
        updated_at: number;
        updated_by: number;
        upload_progress_current: number;
        upload_progress_total: number;
        valid_from: string | null;
        valid_to: string | null;
    }[];
    name: string;
    object_type: number;
    rating_id: number;
    status: number;
    status_publish: number;
    status_publish_requirement: number;
    status_system: number;
    updated_at: number;
    updated_by: number;
    valid_from: string | null;
    valid_to: string | null;
}

type MediaFilter = {
    key: string;
    title: string;
    values: {
        isSelected: boolean;
        key: number;
        title: string;
        total: number;
    }[];
}

type MediaResponse = {
    data: Media[];
    filter: MediaFilter[];
    totalCount: number
}

const getMedia = async (props: MediaProps): Promise<MediaResponse> => {
    let params = `$skip=${props.skip}&$top=${props.top}`;
    if (props.include.length > 0) props.include.forEach((include) => params += `&$include[]=${include}`)
    
  return await request({
    url: `/media?${params}`,
    method: "GET",
  });
}

const PoolService = {
    getMedia
};

export default PoolService;