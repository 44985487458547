const env = process.env["NODE_ENV"];
const env2 = process.env["NODEENV"];
const isDev = process.env.NODE_ENV === 'development';
const isTest = process.env.NODE_ENV === 'test';
//const API_URL = isDev ? `https://capi-staging.strealer.io` : isTest ? 'https://capi-staging.strealer.io' : 'https://capi.strealer.io';
// TODO: Fix this
const API_URL = 'https://capi-staging.strealer.io';

const constants = {
    env,
    env2,
    isDev,
    isTest,
    API_URL,
}

export default constants;