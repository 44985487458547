import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { HeadingLarge } from 'baseui/typography';
import React, { Fragment, ReactNode } from 'react';
import { ListItem, navigationList } from '../../components/UI/Navigation';
import TeamMembers from './TeamMembers';

const Account = () => {
    const [css, theme] = useStyletron();
    const components = [<TeamMembers />,/*  <Locations />, <Preferences />, <Integrations />, <PlansBillingInfo /> */]
    const accountNavigation = navigationList.find((el) => el.href === '/account');
    return (
        <Layout>
            <Block
                className={css({
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.sizing.scale1000
                })}
            >
                <HeadingLarge>{accountNavigation?.label}</HeadingLarge>
                <div className={css({
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.sizing.scale1200,
                })}>
                    {accountNavigation?.items?.map((item, index) => {
                        return (
                            <div key={index} className={css({ display: 'flex', flexDirection: 'column', gap: theme.sizing.scale800, color: '#141414' })}>
                                <div className={css({ borderBottom: '1px solid #ddd', margin: 'scale200' })}>
                                    <ListItem item={item} />
                                </div>
                                {React.cloneElement(components[index] ?? <Fragment />, { item })}
                            </div>
                        )
                    })}
                </div>
            </Block>
        </Layout>
    )
}

const Layout = ({ children }: { children: ReactNode }) => {
    return (
        <Block
            width="100%"
            paddingLeft={['scale200', 'scale400', 'scale600', 'scale1200']}
            paddingRight={['scale200', 'scale400', 'scale600', 'scale1200']}
            paddingTop={['scale1000']}
        >
            {children}
        </Block>
    )
}

const Locations = () => {
    return (
        <p>Locations</p>
    )
}

const Preferences = () => {
    return (
        <p>Preferences</p>
    )
}

const Integrations = () => {
    return (
        <p>Integrations</p>
    )
}

const PlansBillingInfo = () => {
    return (
        <p>PlansBillingInfo</p>
    )
}

export default Account