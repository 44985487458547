import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { FormControl } from 'baseui/form-control';
import { DeleteAlt } from 'baseui/icon';
import { Input } from 'baseui/input';
import { useSnackbar } from 'baseui/snackbar';
import { DisplayMedium, LabelSmall, ParagraphLarge } from "baseui/typography";
import { useAtom } from 'jotai';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Spacer from '../components/Spacer';
import { IUser } from '../models/IUser';
import { ROUTES } from '../models/types';
import UserService, { type LoginFields } from '../services/api/user';
import constants from '../shared/utils/constants';
import { CookieType } from '../shared/utils/types';
import { baseUserAtom } from '../store/userStore';

type FormFieldError = {
    field: string;
    message: string;
}

const Login = () => {
    const [fields, setFields] = useState<LoginFields>({});
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState<FormFieldError[]>([]);
    const [user, setUser] = useAtom(baseUserAtom);
    const navigate = useNavigate();
    const { enqueue } = useSnackbar();

    const isError = (field: string) => {
        if (errors.length <= 0 || typeof errors !== 'object') return false;
        return errors.some((e: FormFieldError) => e.field === field);
    }

    const getErrorMessage = (field: string) => {
        const error = errors.find((e: FormFieldError) => e.field === field);
        return error ? error.message : '';
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.currentTarget;
        setFields((p: LoginFields) => ({ ...p, [name]: value }));
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        setErrors([]);
        try {
            const response = await UserService.login({
                username: fields.username,
                password: fields.password
            });
            if ('access_token' in response) {
                if (constants.isDev) console.log('Setting token', response.access_token, 'to cookie', CookieType.TOKEN);
                Cookies.set(CookieType.TOKEN, response.access_token, {
                    path: "/",
                    expires: 1 // expires 1 day
                });

                // TODO: this API call can be delegated to <AuthenticationMiddleware>
                // TODO: by navigating to root, so that the user will be fetched with the token
                const data: IUser = await UserService.getUser()
                setUser(data);
                
                navigate(ROUTES.POOL);
            }
        } catch (err) {
            if ('data' in (err as any)) setErrors((err as any).data as FormFieldError[]);
            else {
                // TODO: Send event to sentry
                enqueue({
                    message: 'Something went wrong. Please try again later.',
                }, 5000)
            }
        } finally {
            setIsLoading(false);
        }
    }

    // If the user is present, redirect them to the dashboard
    useEffect(() => {
        if (user) navigate(ROUTES.POOL);
    }, [user, navigate]);

    return (
        <Block
            width="100vw"
            height="100vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Block margin="0 auto" padding="0 1rem" width="320px">
                <DisplayMedium style={{ textAlign: 'center' }}>Welcome</DisplayMedium>
                <Spacer size="14px" />
                <ParagraphLarge style={{ textAlign: 'center' }}>Enter your e-mail address and password to get started</ParagraphLarge>
                <Spacer size="14px" />
                <form onSubmit={handleSubmit}>
                    <FormControl
                        htmlFor="username"
                        label={() => "Email"}
                        disabled={isLoading}
                        error={isError('username') && getErrorMessage('username')}
                    >
                        <Input
                            name="username"
                            id="username"
                            value={fields?.username ?? ""}
                            autoComplete="email"
                            placeholder="you@example.com"
                            error={isError('username')}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                    <FormControl
                        htmlFor="password"
                        label={() => "Password"}
                        disabled={isLoading}
                        error={isError('password') && getErrorMessage('password')}
                        labelEndEnhancer={<LabelSmall as={Link} to={ROUTES.FORGOT_PASSWORD} style={{ whiteSpace: 'nowrap' }}>Forgot password?</LabelSmall>}
                    >
                        <Input
                            name="password"
                            id="password"
                            type="password"
                            placeholder="Password"
                            value={fields?.password ?? ""}
                            error={isError('password')}
                            onChange={handleInputChange}
                            endEnhancer={Boolean(fields.password?.length) && <DeleteAlt size={24} style={{ cursor: 'pointer' }} onClick={() => setFields(p => ({ ...p, password: '' }))} />}
                        />
                    </FormControl>
                    <Button isLoading={isLoading} type="submit" overrides={{ Root: { style: { width: '100%' } } }}>
                        Let's go
                    </Button>
                </form>
            </Block>
        </Block>
    )
}

export default Login