import { ForgotPasswordFields } from "../../pages/forgot-password";
import request from "../../shared/lib/request";
import { removeAllCookies } from "../../shared/utils";

/*
 * Gets the user
 */

const getUser = async () => {
  return await request({
    url: `/session/user`,
    method: "GET",
  });
}

/*
 * Gets the active clients
 */

const getActiveClients = async () => {
  return await request({
    url: `/session/active-clients`,
    method: "GET",
  });
}

/*
* Switches the active client
*/

const setActiveClient = async (id: number) => {
 return await request({
   url: `/session/switch-client/${id}`,
   method: "GET",
 });
}


/*
 * Tries to log in the user
 */

const login = async (fields: LoginFields) => {
  return await request({
    url: `/auth/login`,
    method: "POST",
    headers: {
      ...fields
    }
  });
}

/*
 * Tries to log in the user
 */

const resetPassword = async (fields: ForgotPasswordFields) => {
  return await request({
    url: `/auth/reset-password`,
    method: "POST",
    headers: {
      ...fields
    }
  });
}

/*
 * Revokes the authentication session
 */

const revokeSession = async () => {
    const response = await request({
      url: `/auth/logout`,
      method: "GET"
    });
  removeAllCookies();
  window.location.reload();

  return response;
}


const UserService = {
  getUser,
  getActiveClients,
  setActiveClient,
  login,
  resetPassword,
  revokeSession
};

export type LoginFields = {
  username?: string;
  password?: string;
}

export default UserService;