import { useStyletron } from 'baseui';
import { Avatar } from 'baseui/avatar';
import { Button, KIND, SHAPE, SIZE } from 'baseui/button';
import { ButtonGroup } from 'baseui/button-group';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { ListHeading } from 'baseui/list';
import {
    Modal, ModalBody, ModalButton, ModalFooter, ModalHeader
} from 'baseui/modal';
import { Select } from 'baseui/select';
import {
    TableBuilder,
    TableBuilderColumn
} from 'baseui/table-semantic';
import { LabelXSmall } from 'baseui/typography';
import { useAtomValue } from 'jotai';
import { useState } from 'react';
import { NavigationItem } from '../../components/UI/Navigation';
import CloseIcon from '../../shared/assets/icons/CloseIcon';
import { baseUserAtom } from '../../store/userStore';

const TeamMembers = ({ item }: { item?: NavigationItem }) => {
    const [css, theme] = useStyletron();

    const [isInviteUsersModalOpen, setIsInviteUsersModalOpen] = useState(false);
    return (
        <div className={css({ display: 'flex', flexDirection: 'column', gap: theme.sizing.scale600 })}>
            <ListHeading
                heading={item?.label}
                endEnhancer={() => (
                    <Button size={SIZE.compact} shape={SHAPE.pill} onClick={() => setIsInviteUsersModalOpen(true)}>
                        Invite members
                    </Button>
                )}
                maxLines={1}
            />
            <TeamMembersTable />
            <Modal onClose={() => setIsInviteUsersModalOpen(false)} isOpen={isInviteUsersModalOpen}>
                <ModalHeader>Invite members</ModalHeader>
                <ModalBody>
                    <FormControl
                        htmlFor="members"
                        label={() => "Add emails"}
                    >
                        <Select
                            creatable
                            placeholder="john.doe@email.com, jane.smith@mail.org"
                            valueKey="members"
                        />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <ModalButton kind="tertiary" onClick={() => setIsInviteUsersModalOpen(false)}>
                        Cancel
                    </ModalButton>
                    <ModalButton onClick={() => setIsInviteUsersModalOpen(false)}>Send invites</ModalButton>
                </ModalFooter>
            </Modal>
        </div>
    )
}

const users = [{
    name: 'Kert Kaelep',
    email: 'kert.kaelep@gmail.com',
    role: 'Some role'
}, {
    name: 'Kaur Kaelep',
    email: 'kaur.kaelep@gmail.com',
    role: 'Some other role'
}];

const TeamMembersTable = () => {
    const [css, theme] = useStyletron();
    const [isEditMemberModalOpen, setIsEditMemberModalOpen] = useState(false);
    const [isDeleteMemberModalOpen, setIsDeleteMemberModalOpen] = useState(false);
    const [currentSelectedMember, setCurrentSelectedMember] = useState(null)
    const user = useAtomValue(baseUserAtom);

    const toggleEditMemberModal = (member: any) => {
        if (isEditMemberModalOpen) {
            setCurrentSelectedMember(null);
            setIsEditMemberModalOpen(false);
        } else {
            setCurrentSelectedMember(member);
            setIsEditMemberModalOpen(true);
        }
    }

    const toggleDeleteMemberModal = (member: any) => {
        if (isDeleteMemberModalOpen) {
            setCurrentSelectedMember(null);
            setIsDeleteMemberModalOpen(false);
        } else {
            setCurrentSelectedMember(member);
            setIsDeleteMemberModalOpen(true);
        }
    }

    return (
        <>
            <TableBuilder
                data={[{ name: `${user?.first_name} ${user?.last_name} (you)`, email: user?.email, role: user?.role }, ...users]}
                size={SIZE.compact}
            >
                <TableBuilderColumn header="Member">
                    {(row: any) => (
                        <div className={css({ display: 'flex', flexDirection: 'row', gap: theme.sizing.scale300, alignItems: 'center' })}>
                            <Avatar size="scale800" overrides={{ Initials: { style: { fontSize: '12px' } } }} name={row.name} />
                            <div className={css({ display: 'flex', flexDirection: 'column' })}>
                                <span className={row.email === user?.email ? css({ color: theme.colors.accent }) : undefined}>{row.name}</span>
                                <LabelXSmall color={theme.colors.contentSecondary}>{row.email}</LabelXSmall>
                            </div>
                        </div>
                    )}
                </TableBuilderColumn>
                <TableBuilderColumn header="Last seen">
                    {(row: any) => 'A long time ago'}
                </TableBuilderColumn>
                <TableBuilderColumn header="Role">
                    {(row: any) => row.role}
                </TableBuilderColumn>
                <TableBuilderColumn header="Actions">
                    {(row: any) => (
                        <ButtonGroup kind={KIND.secondary} shape={SHAPE.pill} size={SIZE.mini}>
                            <Button disabled={row.email === user?.email} title="Edit" onClick={() => toggleEditMemberModal(row)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#141414" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><path d="M16.474 5.408l2.118 2.117m-.756-3.982L12.109 9.27a2.118 2.118 0 0 0-.58 1.082L11 13l2.648-.53c.41-.082.786-.283 1.082-.579l5.727-5.727a1.853 1.853 0 1 0-2.621-2.621z" /><path d="M19 15v3a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3" /></svg>
                            </Button>
                            <Button disabled={row.email === user?.email} title="Delete" onClick={() => toggleDeleteMemberModal(row)}>
                                <CloseIcon />
                            </Button>
                        </ButtonGroup>
                    )}
                </TableBuilderColumn>
            </TableBuilder>

            <EditTeamMemberModal member={currentSelectedMember as any} onClose={() => setIsEditMemberModalOpen(false)} isOpen={isEditMemberModalOpen} />

            <Modal onClose={() => setIsDeleteMemberModalOpen(false)} isOpen={isDeleteMemberModalOpen}>
                <ModalHeader>Delete Member {(currentSelectedMember as any)?.name}?</ModalHeader>
                <ModalBody>
                    This action is permanent. Are you sure you want to delete this member?
                </ModalBody>
                <ModalFooter>
                    <ModalButton kind="tertiary" onClick={() => setIsDeleteMemberModalOpen(false)}>
                        Cancel
                    </ModalButton>
                    <ModalButton onClick={() => setIsDeleteMemberModalOpen(false)}>Delete</ModalButton>
                </ModalFooter>
            </Modal>
        </>
    );
}
const EditTeamMemberModal = ({ isOpen, onClose, member }: { isOpen: boolean; onClose: () => void; member: { name: string, email: string, role: string } }) => {
    return (
        <Modal onClose={onClose} isOpen={isOpen}>
            <ModalHeader>Edit member</ModalHeader>
            <ModalBody>
                <FormControl
                    htmlFor="name"
                    label={() => "Full name"}
                >
                    <Input
                        name="name"
                        id="name"
                        value={member?.name ?? ""}
                        autoComplete="off"
                        placeholder="John Doe"
                    />
                </FormControl>
                <FormControl
                    htmlFor="email"
                    label={() => "Email"}
                >
                    <Input
                        name="email"
                        id="email"
                        value={member?.email ?? ""}
                        autoComplete="off"
                        placeholder="you@example.com"
                    />
                </FormControl>
                <FormControl
                    htmlFor="role"
                    label={() => "Role"}
                >
                    <Select
                        options={[
                            { label: "AliceBlue", id: "#F0F8FF" },
                            { label: "AntiqueWhite", id: "#FAEBD7" },
                            { label: "Aqua", id: "#00FFFF" },
                            { label: "Aquamarine", id: "#7FFFD4" },
                            { label: "Azure", id: "#F0FFFF" },
                            { label: "Beige", id: "#F5F5DC" }
                        ]}
                        value={[{ label: member?.role, id: member?.role }]}
                        placeholder="Select role"
                    />
                </FormControl>
            </ModalBody>
            <ModalFooter>
                <ModalButton kind="tertiary" onClick={onClose}>
                    Cancel
                </ModalButton>
                <ModalButton onClick={onClose}>Confirm</ModalButton>
            </ModalFooter>
        </Modal >
    )
}

export default TeamMembers